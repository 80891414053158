import React from "react";
import Accordion from "../../../../components/Accordion/Accordion";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import {I18n} from "../../../components/I18n";
import Tooltip from "@material-ui/core/Tooltip";
import Primary from "../../../../components/Typography/Primary";
import VerifiedIcon from "@material-ui/icons/VerifiedUser";
import Button from "../../../../components/CustomButtons/Button";
import Table from "components/Table/Table";
import CustomTabs from "../../../../components/CustomTabs/CustomTabs";
export const InfoSection = ({activity, classes}) => {
    return <Accordion
        active={[0, 1]}
        activeColor="rose"
        collapses={[
            {
                title: "Ogólne",
                content: (
                    <>
                        <GridContainer
                            justfy="space-between"
                            style={{whiteSpace: "nowrap"}}
                        >
                            <GridItem xs={6} sm>
                                <p>
                                    <b>Data:</b>
                                    <br/>
                                    {activity.date}
                                </p>
                            </GridItem>
                            <GridItem xs={6} sm>
                                <p>
                                    <b>Godzina: </b>
                                    <br/>
                                    {activity.start_at} - {activity.stop_at}
                                </p>
                            </GridItem>


                            <GridItem xs={6} sm>
                                <p>
                                    <b>Płeć: </b>
                                    <br/>
                                    {I18n.t(activity.gender || "unisex")}
                                </p>
                            </GridItem>
                            <GridItem xs={6} sm>
                                <p>
                                    <b>Wymagania wiekowe: </b>
                                    <br/>
                                    {activity.min_age >= 18 && activity.max_age >= 100 ? "18+ lat" : <>{activity.min_age} - {activity.max_age} lat</>}

                                </p>
                            </GridItem>
                            <GridItem xs={6} sm>
                                <p>
                                    <b> {activity.verify_lv ? "Wymagany" : 'Zalecany'} poziom: </b>
                                    <br/>
                                    {I18n.t(activity.lv || "free")}<br/>

                                </p>
                            </GridItem>
                            <GridItem xs={6} sm>
                                <p>
                                    <b>Trener: </b>
                                    <br/>
                                    {activity.coach ? "Tak" : "Nie"}
                                </p>
                            </GridItem>
                            <GridItem xs={6} sm>
                                <p>
                                    <b>Koordynator: </b>
                                    <br/>
                                    {(activity.coordinators &&
                                            activity.coordinators.map((co) => {
                                                return <div>{co.label}</div>;
                                            })) ||
                                        "-"}
                                </p>
                            </GridItem>

                            {activity.competition_type == "sparing" && <GridItem xs={6} sm>
                                <p>
                                    <b>Rozmiar drużyn: </b>
                                    <br/>
                                    {activity.min_team_size == activity.max_team_size ? activity.max_team_size : `${activity.min_team_size} - ${activity.max_team_size}`} os.
                                </p>
                            </GridItem>}


                            {activity.pay_by_ok_system && <GridItem xs={6} sm>
                                <p>
                                    <b>Medicover Sport Kod: </b>
                                    <br/>
                                    {activity.ok_system_code}
                                </p>
                            </GridItem>}


                        </GridContainer>
                    </>
                ),
            },
            {
                title: `${activity.competition_type == "activity" ? "Zapisani" : "Ilość drużyn"}: ${activity.canceled ? 0 : activity.all_reserved_spots
                } /
                        ${activity.max_players} (min:${" "}
                        ${activity.min_players}) `,
                content: <>{!activity.canceled && <List activity={activity} classes={classes}/>}</>,
            },
            {
                title: "Lokalizacja",
                content: (
                    <>
                        <h5>
                            <b>Obiekt:</b>
                        </h5>
                        <p>
                            {activity.location_object &&
                                I18n.t(
                                    activity.location_object.object_type
                                )}{" "}
                            <br/>
                            {activity.location_object &&
                                activity.location_object.description}
                        </p>
                        <h5>
                            <b>Adres</b>
                        </h5>
                        <p>
                            {activity.localization &&
                                activity.localization.name}{" "}
                            <br/>
                            {activity.localization &&
                                activity.localization.street}{" "}
                            {activity.localization &&
                                activity.localization.city}
                        </p>
                        <p>
                            {activity.localization &&
                                activity.localization.description}
                        </p>
                    </>
                ),
            },
        ]}
    />
}

const List = ({activity, classes}) => {
    if (!activity.id) {
        return null;
    }

    let list = Array(activity.reserved_spots).fill(
        "Miejsce Zarezerwowane"
    );
    return (<>
        <ul style={{listStyleType: "decimal"}}>
            {list.map((r) => {
                return <li>{r}</li>;
            })}
            {activity.activity_participants.map((r) => {
                const userLevel = r.user.user_sport_levels_attributes.filter((row) => row.sport_id == activity.sport.id)[0]
                return <li>{r.team ? '['+r.team.name+'] - ' : ''}{r.user.label}&nbsp;{userLevel && userLevel.verified && userLevel.level == activity.lv &&
                    <Tooltip title={`Poziom zweryfikowany przez PNH`}
                             classes={{tooltip: classes.tooltip}}>
                        <span><Primary><VerifiedIcon style={{fontSize: 13}}/></Primary></span>
                    </Tooltip>}</li>;
            })}
        </ul>
        {activity.competition_type != 'activity' && <TeamTabs activity={activity} classes={classes} />}
    </>);
};

const TeamTabs = ({activity, classes}) => {
    if(activity.activity_participants.length == 0) {
        return null
    }
    return <div style={{paddingTop: '20px'}}> <CustomTabs
        headerColor="primary"
        tabs={activity.activity_participants.map(p => {
            return  {
                tabName: p.team.name,
                tabContent: <>
                    <Table
                        striped
                        tableHead={[
                            "",
                            `Graczy: ${activity.activity_team_line_ups.length}/${activity.max_team_size}, min: ${activity.min_team_size}`,

                        ]}
                        tableData={ activity.activity_team_line_ups.filter( (atl) => atl.team.id == p.team.id).map((row, i) => [++i, row.player.label])}
                    />
                </>
            }
        })}/></div>



}